import React, { useState } from 'react';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [usersData, setUsersData] = useState(null); // State to hold API data
  const [loading, setLoading] = useState(false); // Loading state

  const toggleDropdown = () => setShowDropdown(prev => !prev);
  const handleLogin = () => setIsLoggedIn(true);
  const handleLogout = () => setIsLoggedIn(false);

  // Function to handle the API request when the card is clicked
  const fetchUsersData = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/user/allUsers');
      const data = await response.json();
      setUsersData(data); // Store API response in state
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUsersData(null); // Clear previous data on error
    }
    setLoading(false);
  };

  return (
      <div style={styles.appContainer}>
        {/* Meta Tag for Viewport */}
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>

        {/* Navigation Bar */}
        <header style={styles.header}>
          <div style={styles.logo}>Logo</div>
          <div style={styles.userSection}>
            {isLoggedIn ? (
                <div style={styles.userIcon} onClick={toggleDropdown}>
                  <span>👤</span>
                  {showDropdown && (
                      <div style={styles.dropdown}>
                        <button style={styles.dropdownButton} onClick={() => alert('User Info')}>用户信息</button>
                        <button style={styles.dropdownButton} onClick={handleLogout}>退出登录</button>
                      </div>
                  )}
                </div>
            ) : (
                <button style={styles.loginButton} onClick={handleLogin}>立即登录</button>
            )}
          </div>
        </header>

        {/* Main Content */}
        <main style={styles.mainContent}>
          <div style={styles.card} onClick={fetchUsersData}>
            <span>Fetching Users</span>
          </div>

          {/* Displaying Users Data */}
          {loading && <p>Loading...</p>}
          {usersData && (
              <div style={styles.usersData}>
                <h3>Users List:</h3>
                <ul>
                  {usersData.map((user, index) => (
                      <li key={index}>{user}</li> // Displaying each user string in the array
                  ))}
                </ul>
              </div>
          )}
        </main>

        {/* Footer */}
        <footer style={styles.footer}>
          <p style={styles.footerText}>© 2024 Your Website. All Rights Reserved.</p>
        </footer>
      </div>
  );
}

const styles = {
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 25px',
    backgroundColor: '#282c34',
    color: '#fff',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  logo: {
    fontSize: '26px',
    fontWeight: 'bold',
    color: '#61dafb',
  },
  userSection: {
    display: 'flex',
    alignItems: 'center',
  },
  userIcon: {
    cursor: 'pointer',
    position: 'relative',
    fontSize: '20px',
    padding: '5px',
    borderRadius: '50%',
    backgroundColor: '#f4f4f4',
  },
  dropdown: {
    position: 'absolute',
    top: '35px',
    right: '0',
    backgroundColor: '#fff',
    color: '#333',
    borderRadius: '8px',
    zIndex: 100,
  },
  dropdownButton: {
    padding: '10px 20px',
    border: 'none',
    backgroundColor: '#fff',
    color: '#333',
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    borderBottom: '1px solid #eee',
  },
  loginButton: {
    padding: '10px 20px',
    backgroundColor: '#61dafb',
    color: '#fff',
    border: 'none',
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#f8f9fa',
    flexGrow: 1,
  },
  card: {
    width: '220px',
    height: '150px',
    backgroundColor: '#ffffff',
    margin: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.05)',
    cursor: 'pointer',
    fontSize: '20px',
    fontWeight: '500',
    color: '#333',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  },
  usersData: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '500px',
  },
  footer: {
    padding: '15px',
    backgroundColor: '#282c34',
    color: '#fff',
    textAlign: 'center',
    fontSize: '14px',
    borderTop: '1px solid #ddd',
  },
  footerText: {
    margin: 0,
  },
};

export default App;
